.mySwiper {
    height: 100px;
}

.swiper-container {
    min-width: 50px;
    height: 150px;
    line-height: 200px;
    text-align: center;
}

.swiper-slide {
    font-size: 14px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0.5;
}

.swiper-slide-active {
    opacity: 1;
}
