* {
  box-sizing: border-box;
}

:root {
  --black: #000000;
  --white: #ffffff;
  --yellow: #fae80a;
  --swiper-theme-color: #fae80a !important;
}
.swiper-pagination-bullet .swiper-pagination-bullet-active {
  background: #fae80a !important;
}
.swiper-pagination-bullet-active {
  color: #fff;
  background: #fae80a !important;
}

a {
  text-decoration: none;
  color: var(--black);
}

.truncate {
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  /* padding-right: 10px; */
}

html,
body {
  min-width: 320px;
}
