.bp-logo {
  width: 64px;
  height: 64px;
}

.bp-or {
  font-weight: 500;
  font-size: 15px;
  text-align: center;
}
