.input-border{
    border: 1px solid rgba(0,0,0,0.23);
    border-radius: 8px;
    height: 51px;
}
.input-border:hover{
    border: 1px solid rgba(0,0,0,0.87);
}
.input-border:focus-within{
    border: 2px solid #fdcc0a;
}
.input-error{
    border: 1px solid #FF706C !important;
}
.react-tel-input .special-label{
    display: none;
    z-index: 2;
}
.react-tel-input{
    /*position: absolute;*/
    /*top: 50%;*/
    /*transform: translateY(-50%);*/
}
.react-tel-input .form-control {
    /*display: none;*/
    visibility: hidden;
    width: calc(100% - 55px);
    background-color: transparent;
    font-size: 16px;
    font-weight: 600;
    padding: 16px 20px;
    margin-left: 55px;
    position: relative;
    z-index: 3;
    border: none;

}
.react-tel-input .form-control:active,.react-tel-input .form-control:focus{
    box-shadow: none;
    border: none;
}
.form-control:hover + .flag-dropdown{
    /*border: 1px solid rgba(0,0,0,0.87);*/
    border: none;
}
.react-tel-input .form-control:active + .flag-dropdown,.react-tel-input .form-control:focus + .flag-dropdown{
    /*border: 2px solid #fdcc0a;*/
}
.react-tel-input .flag-dropdown.open{
    /*border: 2px solid #fdcc0a;*/
}
.react-tel-input .flag-dropdown {
    width: 100%;
    border-radius: 8px;
    /*border: 1px solid rgba(0,0,0,0.23);*/
    border: none;
}

.error{
    border: 1px solid #FF706C;
}

.react-tel-input .selected-flag {
    padding: 0 0 0 11px;
    border-radius: 4px 0 0 4px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.react-tel-input .country-list{
    width: 100%;

}
.react-tel-input .country-list .search{
    padding-right: 8px;
}
.react-tel-input .country-list .search-box{
    margin-left: 0;
    width: 100%;
}