:root {
  --player-height: 100vh; /* Default to 100vh */
}

/* If 100dvh is supported, set --player-height to 100dvh */
@supports (height: 100dvh) {
  :root {
    --player-height: 100dvh;
  }
}
* {
  font-family: 'Urbanist', sans-serif !important;
}

html {
  height: 100%;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* This is for bottom drawer   */
  padding-right: 0 !important;
}
.swiper-pagination-bullet-active {
  color: #fff;
  background: #fae80a !important;
}
.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal .swiper-pagination-bullet-active {
  background: #fae80a !important;
}
